import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO";
import config from "../../data/SiteConfig";
import PodcastLinks from "../components/PodcastLinks"

export default (props) => {

  const { data, pageContext } = props;
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  if (!post.id) {
    post.id = slug;
  }

  const regex = /(\d[:])?\d\d[:]\d\d/g;
  // const regex = /\d\d[:]\d\d/g;
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
  // its $& not $1 for whatever reason on capture group
  // let newHtml = episodeHtml.replace(regex, '<span class="timestamp" onClick={jumpToTimestampAudio(\`$&`)}>$&</span>')
  let newHtml = React.useMemo(
    () =>
      postNode.html.replace(
        regex,
        '<span data-nosnippet class="timestamp" onClick=window.jumpToTimestamp(`$&`)>$&</span>'
      ),
    [postNode.html]
  );


  return (
    <Layout>
      <section className="cc-container cc-text post-container">
        <div className="cc-post-card">
          <div className="cc-padding">
            <Helmet>
              <title>{`${post.title} | ${config.siteTitle}`}</title>
            </Helmet>
            <SEO postPath={slug} postNode={postNode} postSEO />
            <div>
              <h2>{post.title}</h2>
              <div
                className="danger-html"
                dangerouslySetInnerHTML={{ __html: newHtml }}
              />
              {/* This section has next/previous episode links, tags, links to social media, and the download podcast as mp3 link*/}
              {/* <div className="post-meta">
                <PostTags tags={post.tags} />
                <p>Episodes: <Link to={`${pageContext.nextslug}`}>{'<-previous'}</Link>, <Link to="/">{'all'}</Link>, <Link to={`${pageContext.prevslug}`}>{'next->'}</Link></p>
              </div>  */}
            </div>
            <div class="invert-colors">
              <PodcastLinks />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}


/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
        audioPath
        shortDescription
        episodeNumber
      }
      fields {
        slug
        date
      }
    }
  }
`;
